import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/store";
import { Dashboard } from "../../pages/Dashboard";
import { Login } from "../../pages/Login";
import Layout from "./Layout";
import {
  getCurrentJwt,
  getCurrentUser,
  logoutAuth,
  setAuth,
} from "../../features/auth/state/authSlice";
import { getAuthFromLocalStorage } from "../util/localStorage";
import { isTokenExpired } from "../util/auth";
import { ZoomMeetings } from "../../pages/ZoomMeetings";
import { setAuthToken } from "../util/axios";
import SpecificZoomMeeting from "../../pages/ZoomMeetings/specific-zoommeeting";
import Groups from "../../pages/Groups/groups";
import SpecificGroup from "../../pages/Groups/specific-group";
import Users from "../../pages/Users/users";
import SpecificUser from "../../pages/Users/specific-users";
import { Projects } from "../../pages/Projects";
import SpecificProject from "../../pages/Projects/specific-project";
import { Pools } from "../../pages/Pools";
import SpecificPool from "../../pages/Pools/specific-pools";

const Router = () => {
  const { jwt, user } = useSelector((state: RootState) => {
    return {
      jwt: getCurrentJwt(state.auth),
      user: getCurrentUser(state.auth),
    };
  });

  const [IsAuthenticated, SetIsAuthenticated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("jwt", jwt);
    console.log("user", user);

    if (IsAuthenticated == false) {
      console.log("aaaaaaaaaaaaaaaaaaaaaa");
      if (jwt === "" && !user) {
        console.log("0");
        const authFromLocalStorage = getAuthFromLocalStorage();
        if (
          authFromLocalStorage.jwt &&
          authFromLocalStorage.user &&
          authFromLocalStorage.jwt !== null &&
          !isTokenExpired(authFromLocalStorage.jwt)
        ) {
          dispatch(setAuth({ jwt: authFromLocalStorage.jwt, user: authFromLocalStorage.user }));
          setAuthToken(authFromLocalStorage.jwt);
          SetIsAuthenticated(true);
        } else {
          dispatch(logoutAuth());
        }
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />

          <Route path="groups" element={<Groups />} />
          <Route path="groups/:id" element={<SpecificGroup />}>
            {/* <Route index element={<ProjectsList />} />
            <Route path="list" element={<ProjectsList />} />
            <Route path="preview" element={<ProjectGroupPreviewWebGL />} />
            <Route path="team" element={<ProjectGroupCollaborators />} />
            <Route path="participants" element={<ProjectGroupParticipants />} />
            <Route path="publish" element={<ProjectGroupPublish />} /> */}
          </Route>
          <Route path="meetings" element={<ZoomMeetings />} />
          <Route path="meetings/:id" element={<SpecificZoomMeeting />} />
          <Route path="pools" element={<Pools />} />
          <Route path="pools/:id" element={<SpecificPool />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:id" element={<SpecificUser />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:id" element={<SpecificProject />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Theres nothing here!</p>
              </main>
            }
          />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};

export default Router;
