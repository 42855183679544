import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentFullListOfGroups,
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import { groupsColumns } from "../Groups/groups-columns";
import {
  getCurrentGroupsThatAreNotInTheUser,
  getCurrentProjectsThatAreNotInTheUser,
  getCurrentSpecificUser,
  getGroupsThatAreNotInTheUser,
  getProjectsThatAreNotInTheUser,
  getSpecificUser,
  updateSpecificUser,
} from "../../features/users/state/usersSlice";
import {
  Input,
  CustomInputStyle,
  ToggleSwitch,
  Dropdown,
  ButtonIcon,
  DataGridComponent,
  DropdownManyChoices,
} from "ui";
import { ErrorMessage } from "@hookform/error-message";
import { projectsColumns } from "../Projects/projects-columns";
import {
  getCurrentFullListOfProjects,
  getFullListOfProjects,
} from "../../features/projects/state/projectsSlice";

export const GROUPS_COMPONENT_ID = "groupsComponent";
export const PROJECTS_COMPONENT_ID = "projectsComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const GROUPS_NOT_IN_USER_COMPONENT_ID = "groupsNotInUserComponent";
export const UPDATE_PROJECTS_IN_USER_COMPONENT_ID = "UpdateProjectsInUserComponent";
export const UPDATE_GROUPS_IN_USER_COMPONENT_ID = "UpdateGroupsInUserComponent";
export const REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID = "removeThisProjectFromTheUserComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisGroupFromTheUserComponent";
export const USER_UPDATE_COMPONENT_ID = "userUpdateComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";

type IUserInfo = {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  role: any;
  projectsowner: any[];
  groups: any[];
  selectedProjects: any[];
  selectedGroups: any[];
  pools: any[];
  blocked: boolean;
  confirmed: boolean;
};

const SpecificUser = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const {
    specificUser,
    fullListOfGroups,
    fullListOfProjects,
    projectsThatAreNotInTheUser,
    groupsThatAreNotInTheUser,
  } = useSelector((state: RootState) => {
    return {
      specificUser: getCurrentSpecificUser(state),
      fullListOfGroups: getCurrentFullListOfGroups(state),
      fullListOfProjects: getCurrentFullListOfProjects(state),
      projectsThatAreNotInTheUser: getCurrentProjectsThatAreNotInTheUser(state),
      groupsThatAreNotInTheUser: getCurrentGroupsThatAreNotInTheUser(state),
    };
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IUserInfo>({
    defaultValues: {
      firstname: specificUser?.firstname,
      lastname: specificUser?.lastname,
      email: specificUser?.email,
      username: specificUser?.username,
      role: specificUser?.role?.id,
      blocked: specificUser?.blocked,
      confirmed: specificUser?.confirmed,
    },
  });

  const [idOfChosenGroups, setIdOfChosenGroup] = useState<number>();
  const [idOfChosenProjects, setIdOfChosenProject] = useState<number>();

  const [notEditable, setNotEditable] = useState(true);

  const [groupsColumnVisibilityModel, setGroupsColumnVisibilityModel] = useState({
    users: false,
  });

  const [projectsColumnVisibilityModel, setProjectsColumnVisibilityModel] = useState({
    groups: false,
  });

  const location = useLocation();

  const temp = location.pathname.split("/");
  const idOfUser = temp[2];

  useEffect(() => {
    dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));
    dispatch(getFullListOfProjects({ componentId: PROJECTS_COMPONENT_ID }));

    dispatch(
      getSpecificUser({
        componentId: ZOOMMEETINGS_COMPONENT_ID,
        idOfUser: idOfUser,
      }),
    ).then((res: any) => {
      console.log(res.payload);
      reset({
        firstname: res.payload?.firstname,
        lastname: res.payload?.lastname,
        username: res.payload?.username,
        email: res.payload?.email,
        role: res.payload?.role?.id,
        blocked: res.payload?.blocked,
        confirmed: res.payload?.confirmed,
      });
    });
  }, []);

  useEffect(() => {
    dispatch(
      getGroupsThatAreNotInTheUser({
        componentId: GROUPS_NOT_IN_USER_COMPONENT_ID,
        fullListOfGroups: fullListOfGroups as any,
        specificUser: specificUser,
      }),
    );
  }, [specificUser, fullListOfGroups]);

  useEffect(() => {
    dispatch(
      getProjectsThatAreNotInTheUser({
        componentId: GROUPS_NOT_IN_USER_COMPONENT_ID,
        fullListOfProjects: fullListOfProjects as any,
        specificUser: specificUser,
      }),
    );
  }, [specificUser, fullListOfProjects]);

  const selectedProjects: any = watch().selectedProjects;
  const selectedGroups: any = watch().selectedGroups;

  const actionProjectsColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/projects/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeTheseProjectFromTheUser"));
                setIdOfChosenProject(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const actionGroupsColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/groups/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeTheseGroupFromTheUser"));
                setIdOfChosenGroup(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const [pageSize, setPageSize] = useState(10);

  const onSubmitUserInfo = (data: any) => {
    const updatedUser = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email?.toLocaleLowerCase(),
      username: data.username,
      role: data.role,
      blocked: data.blocked,
      confirmed: data.confirmed,
    };

    dispatch(
      updateSpecificUser({
        componentId: USER_UPDATE_COMPONENT_ID,
        idOfUser: specificUser.id,
        data: updatedUser,
      }),
    )
      .then(() => {
        setNotEditable(true);
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const cancel = () => {
    reset({
      firstname: specificUser?.firstname,
      lastname: specificUser?.lastname,
      username: specificUser?.username,
      email: specificUser?.email,
      role: specificUser?.role?.id,
      blocked: specificUser?.blocked,
      confirmed: specificUser?.confirmed,
    });

    setNotEditable(true);
  };

  const onSubmitProjects = () => {
    let allGroupsThatWillBeInTheUser: any = [];

    if (specificUser.projectsowner.length !== 0) {
      specificUser.projectsowner.forEach((users: any) => {
        allGroupsThatWillBeInTheUser.push(users?.id);
      });
    }

    allGroupsThatWillBeInTheUser = [...allGroupsThatWillBeInTheUser, ...selectedProjects];

    const data = { projectsowner: allGroupsThatWillBeInTheUser };

    dispatch(
      updateSpecificUser({
        componentId: UPDATE_PROJECTS_IN_USER_COMPONENT_ID,
        idOfUser: specificUser.id,
        data: data,
      }),
    );
  };

  const onSubmitGroups = () => {
    let allProjectsThatWillBeInTheUser: any = [];

    if (specificUser.groups.length !== 0) {
      specificUser.groups.forEach((users: any) => {
        allProjectsThatWillBeInTheUser.push(users?.id);
      });
    }

    allProjectsThatWillBeInTheUser = [...allProjectsThatWillBeInTheUser, ...selectedGroups];

    const data = { groups: allProjectsThatWillBeInTheUser };

    dispatch(
      updateSpecificUser({
        componentId: UPDATE_GROUPS_IN_USER_COMPONENT_ID,
        idOfUser: specificUser.id,
        data: data,
      }),
    );
  };

  const handleDeleteGroup = (id: number) => {
    const temp = specificUser.groups.filter((item: any) => item.id !== id);

    const arrayOfGroupsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfGroupsId.push(value.id);
    }

    const data = { groups: arrayOfGroupsId };

    dispatch(
      updateSpecificUser({
        componentId: REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID,
        idOfUser: idOfUser,
        data: data,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeTheseGroupFromTheUser"));
    });
  };

  const handleDeleteProject = (id: number) => {
    const temp = specificUser.projectsowner.filter((item: any) => item.id !== id);

    const arrayOfProjectsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfProjectsId.push(value.id);
    }

    const data = { projectsowner: arrayOfProjectsId };

    dispatch(
      updateSpecificUser({
        componentId: REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID,
        idOfUser: idOfUser,
        data: data,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeTheseProjectsFromTheUser"));
    });
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <ModalConfirmation
        id="removeTheseProjectFromTheUser"
        onClick={() => {
          if (idOfChosenProjects !== undefined) {
            handleDeleteProject(idOfChosenProjects);
          }
        }}
        title={t("pages.users.specific.topicOfDeleteProjectsPopup")}
        text={t("pages.users.specific.textOfDeleteProjectsPopup")}
        componentId={REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID}
      />
      <ModalConfirmation
        id="removeTheseGroupFromTheUser"
        onClick={() => {
          if (idOfChosenGroups !== undefined) {
            handleDeleteGroup(idOfChosenGroups);
          }
        }}
        title={t("pages.users.specific.topicOfDeleteGroupsPopup")}
        text={t("pages.users.specific.textOfDeleteGroupsPopup")}
        componentId={REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{`${t("pages.users.specific.pageTitle")}${
            specificUser?.id ? specificUser?.id : ""
          }`}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));
              dispatch(getFullListOfProjects({ componentId: PROJECTS_COMPONENT_ID }));

              dispatch(
                getSpecificUser({
                  componentId: ZOOMMEETINGS_COMPONENT_ID,
                  idOfUser: idOfUser,
                }),
              ).then((res: any) => {
                console.log(res.payload);
                reset({
                  firstname: res.payload?.firstname,
                  lastname: res.payload?.lastname,
                  username: res.payload?.username,
                  email: res.payload?.email,
                  role: res.payload?.role?.id,
                  blocked: res.payload?.blocked,
                  confirmed: res.payload?.confirmed,
                });
              });
            }}
          />
        </div>
      </header>
      <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
        <IsLoading componentId={PROJECTS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={GROUPS_NOT_IN_USER_COMPONENT_ID} showSuccess={false}>
              <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
                <form onSubmit={handleSubmit(onSubmitUserInfo)}>
                  <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                    <div className="w-full h-full max-w-9xl">
                      <div className="relative grow w-full">
                        <div className="border-gray-300">
                          <div className="w-full flex flex-row mb-2">
                            <h2 className="pl-2 py-2 text-xl font-bold">
                              {t("pages.users.specific.info")}
                            </h2>
                            <div className="flex grow justify-end h-[38px] my-auto">
                              {notEditable && (
                                <button
                                  className="btn-primary-fill"
                                  type="button"
                                  onClick={() => {
                                    setNotEditable(false);
                                  }}
                                >
                                  {t("general.edit")}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="grid grid-cols-1 xl:grid-cols-2">
                            <div className="xl:order-1 xl:mr-2">
                              <CustomInputStyle title={t("pages.users.specific.firstname")}>
                                <Input
                                  type="text"
                                  readOnly={notEditable}
                                  registerFct={() => register("firstname")}
                                  className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                  placeholder={t("pages.users.specific.firstNamePlaceholder")}
                                />
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-3 xl:mr-2">
                              <CustomInputStyle title={t("pages.users.specific.lastname")}>
                                <Input
                                  type="text"
                                  readOnly={notEditable}
                                  registerFct={() => register("lastname")}
                                  className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                  placeholder={t("pages.users.specific.lastNamePlaceholder")}
                                />
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-5 xl:mr-2">
                              <CustomInputStyle title={t("pages.users.specific.username")}>
                                <Input
                                  type="text"
                                  readOnly={notEditable}
                                  registerFct={() =>
                                    register("username", { required: true, pattern: /^\S+@\S+$/i })
                                  }
                                  className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                  placeholder={t("pages.users.specific.usernamePlaceholder")}
                                />
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-7 xl:mr-2">
                              <CustomInputStyle title={t("pages.users.specific.email")}>
                                <Input
                                  type="email"
                                  readOnly={notEditable}
                                  registerFct={() =>
                                    register("email", { required: true, pattern: /^\S+@\S+$/i })
                                  }
                                  className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                  placeholder={t("pages.users.specific.emailPlaceholder")}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="email"
                                  render={() => (
                                    <div className="font-small text-red-700">
                                      {"Ne peux pas être vide!"}
                                    </div>
                                  )}
                                />
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-9 xl:mr-2">
                              <CustomInputStyle title={t("general.roles.role")}>
                                <div className="w-full max-w-[250px]">
                                  <Dropdown
                                    options={[
                                      { value: "1", optionText: "Authenticated" },
                                      { value: "2", optionText: "Public" },
                                      { value: "3", optionText: "Admin" },
                                      { value: "4", optionText: "API-lambda" },
                                    ]}
                                    disabled={notEditable}
                                    className={
                                      "font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                    }
                                    style={{
                                      border: errors.role ? "1.5px solid rgb(239 68 68)" : "",
                                    }}
                                    registerFct={() => register("role")}
                                    asDefaultValue={true}
                                  />
                                </div>
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-2 xl:ml-2">
                              <CustomInputStyle title="Googledirouid">
                                <button
                                  className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                  onClick={() =>
                                    openInNewTab(
                                      `https://drive.google.com/drive/folders/${specificUser.googledirouid}?usp=sharing`,
                                    )
                                  }
                                >
                                  {String(t("general.open"))}
                                </button>
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-4 xl:ml-2">
                              <CustomInputStyle title="Googledirouidtracks">
                                <button
                                  className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                  onClick={() =>
                                    openInNewTab(
                                      `https://drive.google.com/drive/folders/${specificUser.googledirouidtracks}?usp=sharing`,
                                    )
                                  }
                                >
                                  {String(t("general.open"))}
                                </button>
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-6 xl:ml-2">
                              <CustomInputStyle title="Googledirouidshared">
                                <button
                                  className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                  onClick={() =>
                                    openInNewTab(
                                      `https://drive.google.com/drive/folders/${specificUser.googledirouidshared}?usp=sharing`,
                                    )
                                  }
                                >
                                  {String(t("general.open"))}
                                </button>
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-8 xl:ml-2">
                              <CustomInputStyle
                                title={t("pages.users.specific.googledocouidshared")}
                              >
                                <button
                                  className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                  onClick={() =>
                                    openInNewTab(
                                      `https://docs.google.com/document/d/${specificUser.googledocouidshared}`,
                                    )
                                  }
                                >
                                  {String(t("general.open"))}
                                </button>
                              </CustomInputStyle>
                            </div>
                            <div className="xl:order-10 xl:ml-2">
                              <CustomInputStyle title={t("pages.users.specific.googledocouid")}>
                                <button
                                  className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                  onClick={() =>
                                    openInNewTab(
                                      `https://docs.google.com/document/d/${specificUser.googledocouid}`,
                                    )
                                  }
                                >
                                  {String(t("general.open"))}
                                </button>
                              </CustomInputStyle>
                            </div>
                          </div>
                          <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-4">
                            <CustomInputStyle title={t("pages.users.specific.blocked")}>
                              <div className="ml-[0.65rem]">
                                <ToggleSwitch
                                  id="blocked"
                                  disabled={notEditable}
                                  registerFct={() => register("blocked")}
                                />
                              </div>
                            </CustomInputStyle>
                            <CustomInputStyle title={t("pages.users.specific.confirmed")}>
                              <div className="ml-[0.65rem] ">
                                <ToggleSwitch
                                  id="confirmed"
                                  disabled={notEditable}
                                  registerFct={() => register("confirmed")}
                                />
                              </div>
                            </CustomInputStyle>
                          </div>
                          <div className="flex justify-end h-[38px]">
                            <IsLoading componentId={USER_UPDATE_COMPONENT_ID} showSuccess={false}>
                              <>
                                {notEditable === false && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn-primary-outline mr-4"
                                      onClick={() => cancel()}
                                    >
                                      {t("general.cancel")}
                                    </button>
                                    <button className="btn-primary-fill" type="submit">
                                      {t("general.save")}
                                    </button>
                                  </>
                                )}
                              </>
                            </IsLoading>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 md:pb-4 mb-16">
                  <h2 className="xl:order-1 text-2xl font-medium mt-10">
                    {t("pages.users.specific.addProjectsToTheUser")}
                  </h2>
                  <form
                    className="xl:order-3 flex border-[1.5px] rounded-xl border-gray-300 md:flex-row flex-col justify-evenly place-content-center"
                    onSubmit={handleSubmit(onSubmitProjects)}
                  >
                    <div className="pt-4 pb-8 m-auto">
                      <div className="w-auto md:w-[320px] lg:w-[420px]">
                        <DropdownManyChoices
                          label="Projects:"
                          name="selectedProjects"
                          control={control}
                          options={projectsThatAreNotInTheUser as any[]}
                          style={{
                            border: errors.projectsowner ? "1.5px solid rgb(239 68 68)" : "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="max-w-xs m-auto md:pt-7 pb-4">
                      <button className="btn-primary-fill" type="submit">
                        {t("general.add")}
                      </button>
                    </div>
                  </form>
                  {specificUser?.projectsowner && specificUser?.projectsowner?.length !== 0 ? (
                    <div className="xl:order-5 p-5 h-full min-h-[200px] w-full bg-white rounded-xl shadow-md">
                      <div className="flex flex-col w-full h-full max-w-9xl">
                        <h2 className="pb-2 text-xl font-bold">
                          {t("pages.users.specific.projects")}
                        </h2>
                        <DataGridComponent
                          autoHeight={true}
                          className="bg-white rounded-xl h-full"
                          rows={specificUser.projectsowner as GridRowsProp}
                          columns={
                            projectsColumns.concat(actionProjectsColumn as any) as GridColDef[]
                          }
                          rowsPerPageOptions={[5, 10, 20]}
                          columnVisibilityModel={projectsColumnVisibilityModel}
                        />
                      </div>
                    </div>
                  ) : (
                    <IsLoading
                      componentId={UPDATE_PROJECTS_IN_USER_COMPONENT_ID}
                      showSuccess={false}
                    >
                      {/* <TableComp rows={getColumnValuesOfPlatforms} colHeaders={colHeadersOfPlatforms} /> */}
                      <div className="xl:order-5 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                        <div className="relative flex flex-col w-full h-full max-w-9xl">
                          <h2 className="pb-2 text-xl font-bold">
                            {t("pages.users.specific.projects")}
                          </h2>
                          <div className="mx-auto my-auto">
                            {t("pages.users.specific.emptyProjectsTable")}
                          </div>
                        </div>
                      </div>
                    </IsLoading>
                  )}
                  <h2 className="xl:order-2 text-2xl font-medium mt-10">
                    {t("pages.users.specific.addGroupsToTheUser")}
                  </h2>
                  <form
                    className="xl:order-4 flex border-[1.5px] rounded-xl border-gray-300 md:flex-row flex-col justify-evenly place-content-center"
                    onSubmit={handleSubmit(onSubmitGroups)}
                  >
                    <div className="pt-4 pb-8 m-auto">
                      <div className="w-auto md:w-[320px] lg:w-[420px]">
                        <DropdownManyChoices
                          label="Groups:"
                          name="selectedGroups"
                          control={control}
                          options={groupsThatAreNotInTheUser as any[]}
                          style={{
                            border: errors.selectedGroups ? "1.5px solid rgb(239 68 68)" : "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="max-w-xs m-auto md:pt-7 pb-4">
                      <button className="btn-primary-fill" type="submit">
                        {t("general.add")}
                      </button>
                    </div>
                  </form>
                  {specificUser?.groups && specificUser?.groups?.length !== 0 ? (
                    <div className="xl:order-6 p-5 h-full min-h-[200px] w-full bg-white rounded-xl shadow-md">
                      <div className="flex flex-col w-full h-full max-w-9xl">
                        <h2 className="pb-2 text-xl font-bold">
                          {t("pages.users.specific.groups")}
                        </h2>
                        <DataGridComponent
                          autoHeight={true}
                          className="bg-white rounded-xl h-full"
                          rows={specificUser.groups as GridRowsProp}
                          columns={groupsColumns.concat(actionGroupsColumn as any) as GridColDef[]}
                          rowsPerPageOptions={[5, 10, 20]}
                          columnVisibilityModel={groupsColumnVisibilityModel}
                        />
                      </div>
                    </div>
                  ) : (
                    <IsLoading componentId={UPDATE_GROUPS_IN_USER_COMPONENT_ID} showSuccess={false}>
                      <div className="xl:order-6 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                        <div className="relative flex flex-col w-full h-full max-w-9xl">
                          <h2 className="pb-2 text-xl font-bold">
                            {t("pages.users.specific.groups")}
                          </h2>
                          <div className="mx-auto my-auto">
                            {t("pages.users.specific.emptyGroupsTable")}
                          </div>
                        </div>
                      </div>
                    </IsLoading>
                  )}
                </div>
              </div>
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </IsLoading>
    </>
  );
};

export default SpecificUser;
