import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IPools {
  fullListOfPools?: any[];
  smListOfPools?: any[];
  specificPool: any;
  projectsThatAreNotInThePool: any[];
  groupsThatAreNotInThePool: any[];
}

export const getSmListOfPools = createAsyncThunk(
  "pools/getSmListOfPools",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.pools.findAll}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfPools = createAsyncThunk(
  "pools/getFullListOfPools",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.pools.findAll}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
        console.log("response", response);
        console.log("res.data", res.data);
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificPool = createAsyncThunk(
  "pools/getSpecificPool",
  async ({ idOfPool }: { idOfPool: string } & IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.pools.get}/${idOfPool}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const updateSpecificPool = createAsyncThunk(
  "pools/updateSpecificPool",
  async ({ idOfPool, data }: { idOfPool: string; data: any } & IAppLoaderAction) => {
    let response;
    console.log(data);
    await axios
      .put(`${apiUrls.pools.get}/${idOfPool}`, { ...data })
      .then((res) => {
        console.log(data);
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

export const getGroupsThatAreNotInThePool = createAsyncThunk(
  "pools/getGroupsThatAreNotInThePool",
  async ({
    specificPool,
    fullListOfGroups,
  }: { specificPool: any; fullListOfGroups: any[] } & IAppLoaderAction) => {
    console.log("specificPool", specificPool);
    console.log("fullListOfGroups", fullListOfGroups);

    if (specificPool?.groups?.length !== 0) {
      const arrOfExistedGroups = [];

      for (let i = 0; i < specificPool.groups.length; i++) {
        arrOfExistedGroups.push(specificPool.groups[i].id);
      }

      let objWithGroupsNotExisted: any = fullListOfGroups;

      arrOfExistedGroups.forEach((id) => {
        if (objWithGroupsNotExisted != undefined) {
          objWithGroupsNotExisted = objWithGroupsNotExisted.filter(
            (element: any) => element.id !== id,
          );
        }
      });

      const optionsNeeded = objWithGroupsNotExisted.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded;
    } else {
      const optionsNeeded = fullListOfGroups.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded as any;
    }
  },
);

export const getProjectsThatAreNotInThePool = createAsyncThunk(
  "pools/getProjectsThatAreNotInThePool",
  async ({
    specificPool,
    fullListOfProjects,
  }: { specificPool: any; fullListOfProjects: any[] } & IAppLoaderAction) => {
    console.log("specificPool", specificPool);
    console.log("fullListOfProjects", fullListOfProjects);

    if (specificPool?.projectsowner?.length !== 0) {
      const arrOfExistedProjects = [];

      for (let i = 0; i < specificPool.projectsowner.length; i++) {
        arrOfExistedProjects.push(specificPool.projectsowner[i].id);
      }

      let objWithProjectsNotExisted: any = fullListOfProjects;

      arrOfExistedProjects.forEach((id) => {
        if (objWithProjectsNotExisted != undefined) {
          objWithProjectsNotExisted = objWithProjectsNotExisted.filter(
            (element: any) => element.id !== id,
          );
        }
      });

      const optionsNeeded = objWithProjectsNotExisted.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded;
    } else {
      const optionsNeeded = fullListOfProjects.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded as any;
    }
  },
);

const initialState: IPools = {
  fullListOfPools: [],
  smListOfPools: [],
  specificPool: {},
  projectsThatAreNotInThePool: [],
  groupsThatAreNotInThePool: [],
};

export const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    clearAllPools: (state: IPools) => {
      state.fullListOfPools = [];
    },
    updateFullListOfPools: (state: IPools, { payload }) => {
      if (state.fullListOfPools?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfPools[state.fullListOfPools?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
    updateSmListOfPools: (state: IPools, { payload }) => {
      if (state.smListOfPools?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfPools[state.smListOfPools?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfPools.fulfilled, (state: IPools, { payload }) => {
      state.smListOfPools = payload;
    });
    builder.addCase(getFullListOfPools.fulfilled, (state: IPools, { payload }) => {
      state.fullListOfPools = payload;
    });
    builder.addCase(getSpecificPool.fulfilled, (state: IPools, { payload }) => {
      state.specificPool = payload;
    });
    builder.addCase(updateSpecificPool.fulfilled, (state: IPools, action) => {
      state.specificPool = action.payload;
      poolsSlice.caseReducers.updateFullListOfPools(state, action);
      poolsSlice.caseReducers.updateSmListOfPools(state, action);
    });
    builder.addCase(getGroupsThatAreNotInThePool.fulfilled, (state: IPools, { payload }: any) => {
      state.groupsThatAreNotInThePool = payload;
    });
    builder.addCase(getProjectsThatAreNotInThePool.fulfilled, (state: IPools, { payload }: any) => {
      state.projectsThatAreNotInThePool = payload;
    });
  },
});

export const poolsReducer = poolsSlice.reducer;

export const getCurrentFullListOfPools = (state: RootState) => state.pools.fullListOfPools;
export const getCurrentSmListOfPools = (state: RootState) => state.pools.smListOfPools;
export const getCurrentSpecificPool = (state: RootState) => state.pools.specificPool;
export const getCurrentProjectsThatAreNotInThePool = (state: RootState) =>
  state.pools.projectsThatAreNotInThePool;
export const getCurrentGroupsThatAreNotInThePool = (state: RootState) =>
  state.pools.groupsThatAreNotInThePool;

export const { clearAllPools } = poolsSlice.actions;
